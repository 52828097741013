import dayjs from 'dayjs'

export const isNotEmpty = (value) => value !== undefined && value !== null && value !== ''

export const getColorFromInitials = (initials) => {
  if (initials) {
    // palette of similar colors starting from #A421BA
    const colors = [
      '#0343DF',
      '#E50000',
      '#75BBFD',
      '#FF81C0',
      '#029386',
      '#F97306',
      '#13EAC9',
      '#FF028D',
      '#0485D1',
      '#FF474C',
      '#536872',
      '#C20078',
      '#7E1E9C',
      '#F79675',
      '#8A6BE3',
      '#C79FEF'
    ]

    var hash = 0
    if (initials.length === 0) return hash
    for (var i = 0; i < initials.length; i++) {
      hash = initials.charCodeAt(i) + ((hash << 5) - hash)
      hash = hash & hash
    }
    hash = ((hash % colors.length) + colors.length) % colors.length
    return colors[hash]
  }
  return ''
}

export const formatDate = (date = new Date()) => {
  if (date) {
    return dayjs(date).format('YYYY/MM/DD')
  }
  return ''
}

export const formatTime = (date = new Date()) => {
  if (date) {
    return dayjs(date).format('HH:mm')
  }
  return ''
}

export const formatString = (value, format) => {
  if (!value) return ''
  for (const char of value) {
    format = format.replace('#', char)
  }
  return format
}

export const stringFormats = {
  PHONE: '+## (###) ###-####',
}

// eslint-disable-next-line no-extend-native
Array.prototype.sortBy = function(fn) {
  return this.sort((a, b) => {
    return fn(a)?.localeCompare(fn(b))
  })
}
