<script setup>
import i18n from '@/plugins/i18n'

import { computed } from 'vue'
import { useVModel } from '@vueuse/core'
import { usePlanningStore } from '@/stores/planning.store'

const t = i18n.global.t
const planningStore = usePlanningStore()

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
  },
  label: {
    type: String,
    required: false,
  },
})

const emit = defineEmits(['update:modelValue'])
const value = useVModel(props, 'modelValue', emit)

const statusLabel = computed(() => t(planningStore.equipmentConditionEnumLabels[value.value]))

const statusColor = computed(() => planningStore.equipmentConditionColors[value.value])

</script>
<template>
  <q-item>
    <q-item-section
      v-if="props.label"
      avatar
    >
      <q-item-label>
        {{ props.label }}
      </q-item-label>
    </q-item-section>
    <q-item-section>
      <q-slider
        id="condition"
        v-model="value"
        class="q-p-sm"
        label-always
        :color="statusColor"
        :min="0"
        :max="4"
        :label-value="statusLabel"
        size="lg"
      />
    </q-item-section>
  </q-item>
</template>
