<script setup>

// import { useVModel } from '@vueuse/core'
import dayjs from 'dayjs'
import { computed } from 'vue'

const props = defineProps({
  modelValue: {
    type: [Object, String, null],
    required: true,
  },
  end: {
    type: Boolean,
    required: false,
  },
  label: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['update:modelValue'])
const time = computed(() => dayjs(props.modelValue).format('HH:mm'))

const onTimeUpdate = (val) => {
  let currentDateTime = dayjs(props.modelValue)
  const value = val.split(':')
  currentDateTime = currentDateTime.set('hour', value[0])
  currentDateTime = currentDateTime.set('minute', value[1])
  emit('update:modelValue', currentDateTime.toISOString())
}

</script>
<template>
  <q-input
    :model-value="time"
    outlined
    readonly
    mask="time"
  >
    <template #append>
      <q-icon
        :name="end ? 'mdi-clock-end' : 'mdi-clock-start'"
        class="cursor-pointer"
      >
        <q-popup-proxy
          cover
          :maximized="false"
          transition-show="scale"
          transition-hide="scale"
        >
          <q-time
            :model-value="time"
            format24h
            @update:model-value="onTimeUpdate"
          >
            <div class="row items-center justify-end">
              <q-btn
                v-close-popup
                label="Close"
                color="primary"
                flat
              />
            </div>
          </q-time>
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-input>
</template>
