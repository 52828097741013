<script setup>
import dayjs from 'dayjs'
import { ref } from 'vue'

import { useNotify } from '@/composables/notify'
import { useStepper } from '@/composables/stepper'
import { useIsUpdated } from '@/composables/object'

import i18n from '@/plugins/i18n'
import { planningApi } from '@/apis/planning.api'
import { usePlanningStore } from '@/stores/planning.store'
import { usePortalStore } from '@/stores/portal.store'

import NavigateBack from '@/components/shared/NavigateBack.vue'
import ConditionSlider from '@/components/shared/ConditionSlider.vue'
import StepperNavigation from '@/components/shared/StepperNavigation.vue'
import QrcodeScanner from '@/components/shared/QrcodeScanner.vue'

const t = i18n.global.t
const planningStore = usePlanningStore()
const portalStore = usePortalStore()

const notify = useNotify()
const { step, nextStep, prevStep } = useStepper()

const emit = defineEmits(['cancel', 'update'])

const props = defineProps({
  modelValue: {
    type: [Object, null],
    required: true,
  },
  project: {
    type: Object,
    required: true,
  },
  tagId: {
    type: Number,
    required: true,
  },
})

const availableTags = portalStore.getLocalizedTags().filter(i => i.type === portalStore.serviceTypeEnum.EQUIPMENT)
const rentalCompanies = ref((await planningStore.fetchCompaniesAsync(true))?.filter(i => i.is_rental))
const isScanning = ref(false)
const scanningTimeout = ref(null)
const isUpdating = ref(false)
const isDeleting = ref(false)

const editItem = ref({
  condition: 3,
  tags: [props.tagId],
  type: portalStore.serviceTypeEnum.EQUIPMENT,
  availability_type: portalStore.serviceAvailabilityTypeEnum.USUALLY_UNAVAILABLE,
})
const { isUpdated } = useIsUpdated(editItem.value)

if (props.modelValue.id) {
  editItem.value = (await planningApi.getEquipmentByIdAsync(props.modelValue.id)).data
}
if (!editItem.value.availability || editItem.value.availability.length === 0) {
  editItem.value.availability = [{
    from: dayjs(props.project.start).format('YYYY/MM/DD'),
    to: dayjs(props.project.end).format('YYYY/MM/DD')
  }]
} else {
  editItem.value.availability = editItem.value.availability.map(i => ({
    from: dayjs(i.start).format('YYYY/MM/DD'),
    to: dayjs(i.end).format('YYYY/MM/DD'),
  }))
}

const onScan = () => (isScanning.value = true)

const onScanError = (error) => {
  console.error(error)
  notify.error(t('error.qrCodeNotFound'))
  isScanning.value = false
}

const onDetect = (codes) => {
  clearTimeout(scanningTimeout.value)
  try {
    const decodedValue = JSON.parse(codes[0].rawValue)
    notify.success(`${t('common.qrCodeDetected')}!`)
    editItem.value.barcode = decodedValue.rawValue || decodedValue
  } catch (error) {
    notify.error(t('error.qrCodeNotFound'))
  } finally {
    isScanning.value = false
  }
}

const onUpdate = async() => {
  isUpdating.value = true
  const updateItem = { ...editItem.value }

  updateItem.availability = editItem.value.availability.map(i => ({
    start: dayjs(i?.from || i).toISOString(),
    end: dayjs(i?.to || i).toISOString(),
  }))

  if (!editItem.value.avatar_file?.payload) {
    delete updateItem.avatar_file
  }

  await planningStore.upsertEquipmentAsync(updateItem)
  await planningStore.fetchEquipmentsAsync(true)

  notify.success(t('info.updatedSuccessfully'))

  isUpdating.value = false
  emit('update', updateItem)
}

const onDelete = async() => {
  try {
    isDeleting.value = true
    await planningApi.deleteEquipmentAsync(editItem.value.id)
    notify.success(t('info.deletedSuccessfully'))
    isDeleting.value = false
    emit('update')
  } catch (e) {
    notify.error(t('error.deleteFailed'))
  }
}

const fileInput = ref(null)
const onActivateCamera = () => {
  fileInput.value.click()
  isCameraActive.value = true
}

const isCameraActive = ref(false)

const onSnapshot = async(event) => {
  const file = event.target.files[0]

  if (file) {
    const reader = new FileReader()
    reader.onload = () => {
      editItem.value.avatar_file = {
        name: file.name,
        payload: reader.result
      }
    }
    reader.readAsDataURL(file)
  }

  editItem.value.avatarUrl = URL.createObjectURL(file)
  isCameraActive.value = false
}

const onRentalCompanySelected = (value) => {
  if (!value) {
    editItem.value.rental_company = null
  } else if (typeof value === 'string') {
    editItem.value.rental_company = { name: value, is_rental: true }
  } else {
    editItem.value.rental_company = rentalCompanies.value.find(i => i.id === value)
  }
}

</script>
<template>
  <q-card>
    <q-card-section>
      <q-list>
        <navigate-back
          :text="t('common.rentedEquipment')"
          @back="emit('cancel')"
        />
      </q-list>
    </q-card-section>
    <q-card-section class="q-px-sm q-pt-none">
      <q-stepper
        v-model="step"
        class="q-py-none"
      >
        <q-step
          :name="1"
          :title="t('common.basicInfo')"
          icon="mdi-folder-plus"
          :done="!!editItem.id || step > 1"
          :header-nav="!!editItem.id || step > 1"
        >
          {{ t('project.assign.rental.basicInfoDesc') }}
          <q-input
            v-model="editItem.name"
            class="q-mt-sm"
            outlined
            label="Name"
          />
          <q-select
            v-model="editItem.tags"
            class="q-mt-sm"
            behavior="menu"
            use-chips
            multiple
            :label="t('common.tag', 2)"
            option-value="id"
            option-label="name"
            :options="availableTags"
          />
          <q-input
            v-model="editItem.details"
            class="q-mt-sm"
            :label="t('common.detail', 2)"
            type="textarea"
          />
          <stepper-navigation
            :disable="!editItem.name || editItem.name === '' || !editItem.tags || editItem.tags.length === 0"
            :continue="nextStep"
          />
        </q-step>
        <q-step
          :name="2"
          :title="t('common.rentalInfo')"
          icon="mdi-calendar-clock"
          :done="!!editItem.id || step > 2"
          :header-nav="!!editItem.id || step > 2"
        >
          <q-select
            :model-value="editItem.rental_company"
            use-input
            class="q-mt-sm"
            behavior="menu"
            new-value-mode="add-unique"
            :label="t('common.company')"
            option-value="id"
            option-label="name"
            :options="rentalCompanies"
            @update:model-value="onRentalCompanySelected"
          />
          <q-input
            :model-value="editItem.rental_company?.contact"
            class="q-mt-sm"
            :label="t('common.contact')"
            :disable="!editItem.rental_company"
            @update:model-value="editItem.rental_company.contact = $event"
          />
          <q-input
            :model-value="editItem.rental_company?.address"
            :disable="!editItem.rental_company"
            class="q-mt-sm"
            :label="t('common.address')"
            type="textarea"
            @update:model-value="editItem.rental_company.address = $event"
          />
          <stepper-navigation
            :disable="!editItem.rental_company"
            :continue="nextStep"
            :back="prevStep"
          />
        </q-step>
        <q-step
          :name="3"
          :title="`${t('common.picture')}, ${t('common.barcode')} & ${t('common.condition', 2)}`"
          icon="mdi-calendar-clock"
          :done="!!editItem.id || step > 3"
          :header-nav="!!editItem.id || step > 3"
        >
          <div style="display: flex; flex-flow: column; align-items: center;">
            <input
              ref="fileInput"
              type="file"
              accept="image/png"
              capture="environment"
              style="display: none;"
              @change="onSnapshot"
            >
            <q-btn
              v-if="!editItem.avatar_file && !editItem.avatar_url"
              class="q-mt-lg"
              icon="mdi-camera"
              size="2rem"
              round
              @click="onActivateCamera"
            />
            <q-img
              v-else
              class="q-mt-lg"
              style="height: 10rem; width: 100%; box-shadow: 0 0 10px 0 rgba(0,0,0,0.25); border: 1px solid #aaa;"
              :src="editItem.avatar_file?.payload || editItem.avatar_file || editItem.avatar_url"
              @click="onActivateCamera"
            />
            <qrcode-scanner
              v-if="isScanning"
              style="position: fixed; top: 5rem; bottom: 1rem; z-index: 100;"
              :timeout="5000"
              :formats="null"
              @detect="onDetect"
              @timeout="onScanError"
              @error="onScanError"
            />
            <q-btn
              class="q-mt-lg"
              icon="mdi-qrcode-scan"
              size="2rem"
              round
              :text-color="editItem.barcode ? 'positive' : 'black'"
              :disabled="isScanning"
              :loading="isScanning"
              @click="onScan"
            />
            <q-separator class="q-mt-lg" />
            <condition-slider
              v-model="editItem.condition"
              style="width: 100%;"
              class="q-mt-lg"
            />
          </div>
          <stepper-navigation
            :continue="nextStep"
            :back="prevStep"
          />
        </q-step>
        <q-step
          :name="4"
          :title="t('common.availability')"
          icon="mdi-check"
          :done="!!editItem.id || step > 4"
          :header-nav="!!editItem.id || step > 4"
        >
          <q-date
            v-model="editItem.availability"
            first-day-of-week="1"
            multiple
            range
            today-btn
          />
          <stepper-navigation
            :back="prevStep"
            :finish="editItem.id ? null : onUpdate"
            :finish-label="t('common.createEquipment')"
          />
        </q-step>
      </q-stepper>
    </q-card-section>
    <q-space />
    <q-card-section
      v-if="editItem?.id"
      class="relative-bottom align-center"
    >
      <q-btn
        class="large-btn q-mr-sm"
        color="negative"
        :label="t('common.deleteEquipment')"
        :loading="isDeleting"
        @click="onDelete()"
      />
      <q-btn
        v-if="isUpdated"
        class="large-btn"
        color="positive"
        :label="t('common.updateEquipment')"
        :loading="isUpdating"
        @click="onUpdate()"
      />
    </q-card-section>
  </q-card>
</template>
