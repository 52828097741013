import { defineStore } from 'pinia'
import { ref, toRaw } from 'vue'
import i18n, { updateLocaleAsync, getTranslatedItems } from '@/plugins/i18n'

import { useGetOrFetchAsync } from '@/composables/store'

import { portalApi } from '@/apis/portal.api'

import { useWebSiteStore } from './web-site.store'
import { updateQuasarLocaleAsync } from '@/plugins/quasar'

export const initPortalStoreAsync = async() => {
  const portalStore = usePortalStore()
  const webSiteStore = useWebSiteStore()

  const promisesArray = [portalStore.fetchVersionAsync()]

  if (webSiteStore.isAuthenticated()) {
    promisesArray.push(portalStore.fetchTagsAsync(true))
  }
  await Promise.all(promisesArray)
}

export const usePortalStore = defineStore('portal', () => {
  const reloadKey = ref(0)
  const version = ref(null)
  const selectedLanguage = ref(window.config.DEFAULT_LANGUAGE)
  const languages = ref([])
  const localizations = ref({})
  const tags = ref([])

  const serviceTypeEnum = Object.freeze({
    STAFF: 0,
    EQUIPMENT: 1,
  })

  const serviceStatusEnum = Object.freeze({
    AVAILABLE: 0,
    COMMITTED: 1,
    UNAVAILABLE: 2,
  })

  const serviceAvailabilityEnum = Object.freeze({
    UNAVAILABLE: 0,
    PARTIALLY_AVAILABLE: 1,
    AVAILABLE: 2,
    ASK: 3,
  })

  const serviceAvailabilityTypeEnum = Object.freeze({
    USUALLY_UNAVAILABLE: 0,
    USUALLY_AVAILABLE: 1,
    ASK: 2,
  })

  const userRoleEnum = Object.freeze({
    ADMINISTRATOR: 1,
    FINANCIAL: 2,
    PRODUCER: 4,
    COLLABORATOR: 8,
    STAKEHOLDER: 16,
  })

  const incrementReloadKey = () => { reloadKey.value++ }

  const fetchVersionAsync = async() => {
    const res = await portalApi.getVersionAsync()
    const currentVersion = res.data && res.data.version

    const isVersionChanged = currentVersion !== version.value

    if (isVersionChanged) {
      await fetchLanguagesAsync()
      version.value = currentVersion
    }
    await setLanguageAsync(selectedLanguage.value, isVersionChanged)
  }

  const fetchLanguagesAsync = async() => {
    const res = await portalApi.getLanguagesAsync()
    if (res.data) {
      languages.value = res.data
    }
  }

  const fetchLocalizationsAsync = async(languageCode = selectedLanguage.value, forceUpdate = false) => {
    if ((!localizations.value[languageCode]) || localizations.value[languageCode].length === 0 || forceUpdate) {
      const res = await portalApi.getLocalizationsAsync(languageCode)
      if (res.data) {
        localizations.value[languageCode] = res.data
      }
    }
  }
  const getLocalizedLanguages = () => languages.value.map((item) => {
    return {
      ...item,
      name: i18n.global.t(item.name)
    }
  })
  const getLocalizedTags = () => getTranslatedItems(tags.value)

  const setLanguageAsync = async(languageCode = selectedLanguage.value, forceUpdate = false) => {
    await fetchLocalizationsAsync(languageCode, forceUpdate)
    await updateLocaleAsync(languageCode, toRaw(localizations.value))
    await updateQuasarLocaleAsync(languageCode)
    selectedLanguage.value = languageCode
  }

  const fetchTagsAsync = async(force = false) => useGetOrFetchAsync(tags, () => portalApi.getTagsAsync(), force)
  const getTagById = (id) => tags.value.find(tag => tag.id === id)

  const getLocalizedServiceAvailabilityTypes = () => Object.keys(serviceAvailabilityTypeEnum).map((i) => ({ id: serviceAvailabilityTypeEnum[i], name: i18n.global.t(`common.serviceAvailabilityTypes.${i.toLocaleLowerCase()}`)}))
  const getLocalizedServiceStatuses = () => Object.keys(serviceStatusEnum).map((i) => ({ id: serviceStatusEnum[i], name: i18n.global.t(`common.serviceStatuses.${i.toLocaleLowerCase()}`)}))
  const getLocalizedUserRoles = () => Object.keys(userRoleEnum).map((i) => ({ id: userRoleEnum[i], name: i18n.global.t(`common.userRoles.${i.toLocaleLowerCase()}`)}))

  const $reset = () => {
    version.value = null
    selectedLanguage.value = window.config.DEFAULT_LANGUAGE
    languages.value = []
    localizations.value = {}
    customerPrivacyModules.value = []
    tags.value = []
  }

  const localizationsReset = () => {
    localizations.value = {}
  }

  return {
    languages,
    localizations,
    reloadKey,
    selectedLanguage,
    serviceAvailabilityEnum,
    serviceAvailabilityTypeEnum,
    serviceTypeEnum,
    serviceStatusEnum,
    tags,
    userRoleEnum,
    version,
    $reset,
    fetchLanguagesAsync,
    fetchLocalizationsAsync,
    fetchTagsAsync,
    fetchVersionAsync,
    getLocalizedLanguages,
    getLocalizedServiceAvailabilityTypes,
    getLocalizedServiceStatuses,
    getLocalizedTags,
    getLocalizedUserRoles,
    getTagById,
    incrementReloadKey,
    localizationsReset,
    setLanguageAsync,
  }
}, {
  persist: {
    enabled: true,
    strategies: [
      { storage: localStorage, paths: ['version', 'selectedLanguage', 'languages', 'localizations', 'customerPrivacyModules'] },
    ],
  },
})
