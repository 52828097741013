<script setup>

import { ref, computed, onMounted } from 'vue'

import i18n from '@/plugins/i18n'

import { useWebSiteStore } from '@/stores/web-site.store'
import { usePlanningStore } from '@/stores/planning.store'

const props = defineProps({
  projectsDataSource: {
    type: Array,
    required: true,
  },
  staffDataSource: {
    type: Array,
    required: true,
  },
  equipmentDataSource: {
    type: Array,
    required: true,
  },
  view: {
    type: Number,
    required: true,
    default: 0,
  },
})

const emit = defineEmits(['viewChange', 'selectionChange'])

const t = i18n.global.t
const planningStore = usePlanningStore()
const webSiteStore = useWebSiteStore()

const projectsDataSource = ref(props.projectsDataSource)
const staffDataSource = ref(props.staffDataSource)
const equipmentDataSource = ref(props.equipmentDataSource)

onMounted(async() => {
  const promises = []
  if (!projectsDataSource.value || projectsDataSource.value.length === 0) {
    promises.push(planningStore.fetchProjectsAsync(true))
  }
  if (!staffDataSource.value || staffDataSource.value.length === 0) {
    promises.push(planningStore.fetchStaffsAsync(true))
  }
  if (!equipmentDataSource.value || equipmentDataSource.value.length === 0) {
    promises.push(planningStore.fetchEquipmentsAsync(true))
  }
  await Promise.all(promises)
  projectsDataSource.value = planningStore.projects.map(i => ({...i, visible: true}))
  staffDataSource.value = planningStore.staff.map(i => ({...i, visible: true}))
  equipmentDataSource.value = planningStore.equipments.map(i => ({...i, visible: true}))
})

const currentViewValue = computed(() => props.view === webSiteStore.schedulerViews.PROJECTS ? false : props.view === webSiteStore.schedulerViews.STAFF ? null : true)

const currentItemCounter = computed(() => {
  return props.view === webSiteStore.schedulerViews.PROJECTS ? projectsDataSource.value.filter(i => i.visible).length
    : props.view === webSiteStore.schedulerViews.STAFF ? staffDataSource.value.filter(i => i.visible).length
      : equipmentDataSource.value.filter(i => i.visible).length
})

const toggleAll = computed(() => {
  let dataSource = projectsDataSource.value
  if (props.view === webSiteStore.schedulerViews.STAFF) {
    dataSource = staffDataSource.value
  } else if (props.view === webSiteStore.schedulerViews.EQUIPMENT) {
    dataSource = equipmentDataSource.value
  }
  const visibleItems = dataSource.filter(i => i.visible).length
  return visibleItems === 0 ? false : visibleItems === dataSource.length ? true : undefined
})

const onToggleAllClick = () => {
  const visibility = !toggleAll.value
  if (props.view === webSiteStore.schedulerViews.PROJECTS) {
    projectsDataSource.value.forEach(i => {
      i.visible = visibility
      return i
    })
  } else if (props.view === webSiteStore.schedulerViews.STAFF) {
    staffDataSource.value.forEach(i => {
      i.visible = visibility
      return i
    })
  } else if (props.view === webSiteStore.schedulerViews.EQUIPMENT) {
    equipmentDataSource.value.forEach(i => {
      i.visible = visibility
      return i
    })
  }
}

const onViewChange = (val) => {
  emit('viewChange', val === false ? webSiteStore.schedulerViews.PROJECTS : val === null ? webSiteStore.schedulerViews.STAFF : webSiteStore.schedulerViews.EQUIPMENT)
}

const onSelectionChange = () => {
  let dataSource = projectsDataSource.value
  if (props.view === webSiteStore.schedulerViews.STAFF) {
    dataSource = staffDataSource.value
  } else if (props.view === webSiteStore.schedulerViews.EQUIPMENT) {
    dataSource = equipmentDataSource.value
  }
  emit('selectionChange', dataSource.filter(i => i.visible).map(i => i.id))
}

</script>
<template>
  <div style="display: flex; flex-flow: row; align-items: center;">
    <q-toggle
      :model-value="currentViewValue"
      :class="{ 'projects-toggle': props.view === webSiteStore.schedulerViews.PROJECTS, 'staff-toggle': props.view === webSiteStore.schedulerViews.STAFF}"
      toggle-indeterminate
      keep-color
      :icon="props.view === webSiteStore.schedulerViews.PROJECTS ? 'mdi-folder' : props.view === webSiteStore.schedulerViews.STAFF ? 'mdi-account' : 'mdi-inbox-arrow-down'"
      :color="props.view === webSiteStore.schedulerViews.PROJECTS ? 'blue' : props.view === webSiteStore.schedulerViews.STAFF ? 'primary' : 'positive'"
      @update:model-value="onViewChange"
    />
    <div>
      <span class="text-bold q-pr-xs">
        {{ props.view === webSiteStore.schedulerViews.PROJECTS ? t('common.project', currentItemCounter) : props.view === webSiteStore.schedulerViews.STAFF ? t('common.staff') : t('common.equipment', currentItemCounter) }}
      </span>
      <q-badge
        v-show="currentItemCounter > 0"
        rounded
        align="top"
        color="blue"
      >
        {{ currentItemCounter }}
      </q-badge>
      <q-menu
        transition-show="jump-down"
        transition-hide="jump-up"
        @hide="onSelectionChange"
      >
        <q-card>
          <q-list>
            <q-item
              clickable
              class="q-pa-sm"
              @click="onToggleAllClick"
            >
              <q-item-section
                side
                class="q-pr-sm"
              >
                <q-checkbox
                  :model-value="toggleAll"
                  size="sm"
                  @update:model-value="onToggleAllClick"
                />
              </q-item-section>
              <q-item-section>
                <q-item-label class="q-pr-sm">
                  {{ t('common.all') }}
                </q-item-label>
              </q-item-section>
            </q-item>
            <q-item
              v-for="item in props.view === webSiteStore.schedulerViews.PROJECTS ? projectsDataSource : props.view === webSiteStore.schedulerViews.STAFF ? staffDataSource : equipmentDataSource"
              :key="item.id"
              clickable
              class="q-pa-sm"
              @click="item.visible = !item.visible"
            >
              <q-item-section
                side
                class="q-pr-sm"
              >
                <q-checkbox
                  v-model="item.visible"
                  size="sm"
                />
              </q-item-section>
              <q-item-section>
                <q-item-label class="q-pr-sm">
                  {{ item.name }}
                  <span v-if="props.view === webSiteStore.schedulerViews.STAFF">
                    {{ item.first_name }}
                  </span>
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card>
      </q-menu>
    </div>
  </div>
</template>
<style lang="scss">
@import '@/styles/quasar.variables.scss';
.projects-toggle {
  & .q-toggle__thumb:after {
    background-color: $blue !important;
  }
  & .q-toggle__track {
    background-color: $blue !important;
  }
  & .q-toggle__thumb .q-icon{
    color: $white !important;
    opacity: 1 !important;
  }
}
.staff-toggle {
  & .q-toggle__thumb:after {
    background-color: $primary !important;
  }
  & .q-toggle__track {
    background-color: $primary !important;
  }
  & .q-toggle__thumb .q-icon{
    color: $white !important;
    opacity: 1 !important;
  }
}
</style>
