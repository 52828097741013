import { useNotify } from '@/composables/notify'
import { paramsSubstituteTranslate } from '@/plugins/i18n'
import { useWebSiteStore } from '@/stores/web-site.store'
import axios from 'axios'

// const CSRF_COOKIE_NAME = 'csrftoken'
// const CSRF_HEADER_NAME = 'X-CSRFToken'

const httpApi = axios.create({
  baseURL: window.config.BASE_API,
  timeout: window.config.TIMEOUT,
})

// // Request Interceptor
httpApi.interceptors.request.use(config => {
  return config
}, error => {
  // showMessage(error, messageType.ERROR)
})

// Response Interceptor
httpApi.interceptors.response.use(
  response => {
    if (response.status >= 400) {
      if (!response.config.skipErrorMessage) {
        // showMessage(responseData.message, messageType.ERROR)
      }

      return Promise.reject('error')
    } else {
      return response
    }
  },
  error => {
    console.error(error)

    const processError = async(err) => {
      if (!err.config || !err.config.skipErrorMessage) {
        let message = 'error.generic'
        let params = {}
        let type = 'error'

        if (err) {
          message = err.message || err
        }

        if (err.response && err.response.data) {
          message = err.response.data.message || err.response.data.toString()
          params = err.response.data.params || {}
        }
        if (err.response && err.response.status === 401) {
          message = err.response.data?.message || 'error.unauthorized'
          type = 'error'
          const webSiteStore = useWebSiteStore()
          try {
            await webSiteStore.refreshTokenAsync()
          } catch (error) {
            await webSiteStore.logoutAsync()
          }
        } else if (err.response && err.response.status === 404) {
          if (window.config.IS_DEBUG) {
            console.debug(`${err.response.statusText} - ${err.response.config.url}`)
          } else {
            message = 'warning.file_not_found'
            type = 'warning'
          }
        } else if (message.startsWith('error')) {
          type = 'error'
        } else if (message.startsWith('warning')) {
          type = 'warning'
        } else {
          message = 'error.generic'
        }
        const { notifyError, notifyWarning } = useNotify()
        if (type === 'warning') {
          notifyWarning(paramsSubstituteTranslate(message, params) || message)
        } else {
          notifyError(paramsSubstituteTranslate(message, params) || message)
        }
      }
    }

    if (error.response && error.response.config.responseType === 'blob') {
      return new Promise((resolve, reject) => {
        const blb = new Blob([error.response.data])
        const reader = new FileReader()
        reader.readAsText(blb)
        reader.addEventListener('loadend', (e) => {
          error.response.data = { detail: e.srcElement.result }
          processError(error)
          reject(error)
        })
      })
    } else {
      processError(error)
      return Promise.reject(error)
    }
  }
)

export const setCommonHeader = (header, value) => {
  httpApi.defaults.headers.common[header] = value
}

export const setAuthorizationToken = (token) => {
  !token ? setCommonHeader('Authorization', null) : setCommonHeader('Authorization', `Bearer ${token}`)
}

export const setAcceptLanguageHeader = (language) => {
  httpApi.defaults.headers.common['Accept-Language'] = language
}

export default httpApi
