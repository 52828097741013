<script setup>
import '@/styles/style.scss'
import { ref, onMounted } from 'vue'
import router from '@/router.js'

const bar = ref(null)

onMounted(() => {
  router.beforeEach((to, from, next) => {
    bar.value.start()
    next()
  })
  router.afterEach(() => {
    bar.value.stop()
  })
})

</script>
<template>
  <q-ajax-bar
    ref="bar"
  />
  <Suspense>
    <router-view v-slot="{ Component }">
      <transition
        appear
        mode="out-in"
        duration="500"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <component :is="Component" />
      </transition>
    </router-view>
  </Suspense>
</template>
