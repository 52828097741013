<script setup>
import { ref, computed } from 'vue'

import i18n from '@/plugins/i18n'
import { usePlanningStore } from '@/stores/planning.store'
import { usePortalStore } from '@/stores/portal.store'

import ListItem from '@/components/shared/ListItem.vue'
import { useNotify } from '@/composables/notify'
import { useDetectDevice } from '@/composables/device'

import ProjectAssign from './ProjectAssign.vue'
import ProjectEdit from './ProjectEdit.vue'
import NavigateBack from '../shared/NavigateBack.vue'

const { isMobile } = useDetectDevice()

const t = i18n.global.t
const notify = useNotify()
const planningStore = usePlanningStore()
const portalStore = usePortalStore()

await Promise.all([
  planningStore.fetchListingItemsAsync(true),
  portalStore.fetchTagsAsync(true)
])

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['cancel', 'update'])

const projectListingItemServices = ref([])
const projectItem = ref(props.modelValue)
const editItem = ref(null)
const assignItem = ref(null)

const refreshProject = async() => {
  projectItem.value = (await planningStore.fetchProjectByIdAsync(projectItem.value.id)).data
}

const refreshProjectItemServicesAsync = async() => {
  projectListingItemServices.value = (await planningStore.fetchProjectListingItemServicesByProjectIdAsync(props.modelValue.id)).data?.map(i => ({
    ...i,
    tag: portalStore.getLocalizedTags().find(t => t.id === i.tag_id),
  })) || []
}
await refreshProjectItemServicesAsync()

const staffTagServices = computed(() => projectListingItemServices.value.filter(i => i.tag.type === portalStore.serviceTypeEnum.STAFF).sortBy(i => i.tag.name))
const equipmentTagServices = computed(() => projectListingItemServices.value.filter(i => i.tag.type === portalStore.serviceTypeEnum.EQUIPMENT).sortBy(i => i.tag.name))

const onAssignItem = async(item) => {
  if (!item.service_id) {
    item.start = projectItem.value.start
    item.end = projectItem.value.end
  }
  assignItem.value = item
}

const onUnassignItem = async(item) => {
  await planningStore.upsertProjectListingItemServiceAsync({ id: item.id, service: null })
  notify.success('Assignment removed')
  await refreshProjectItemServicesAsync()
}

const onAssignUpdate = async() => {
  assignItem.value = null
  await refreshProjectItemServicesAsync()
}

const selectionKey = ref(0)

const onProjectUpdate = async() => {
  editItem.value = null
  await Promise.all([
    refreshProject(),
    refreshProjectItemServicesAsync(),
  ])
}

</script>
<template>
  <q-card>
    <q-card-section>
      <q-list>
        <navigate-back
          :text="projectItem.name"
          :icon="projectItem.status !== planningStore.projectStatusEnum.PLANNED ? 'mdi-magnify' : 'mdi-pencil'"
          @back="emit('cancel')"
          @click="editItem = projectItem"
        />
      </q-list>
    </q-card-section>
    <q-card-section class="q-pt-none">
      <q-list
        :key="selectionKey"
        padding
      >
        <q-item-label
          header
        >
          {{ t('common.staff') }}
        </q-item-label>
        <list-item
          v-for="staff in staffTagServices"
          :key="staff.id"
          :avatar="staff.service_id ? (staff.service_avatar_file || staff.service_avatar_url) : null"
          :avatar-icon="staff.service_id ? null : 'mdi-crop-free'"
          :label="staff.service_id ? `${staff.service_name} ${staff.service_first_name}` : staff.tag.name"
          :caption="staff.service_id ? staff.tag.name : null"
          clickable
          :icon="staff.service_id ? 'mdi-minus-circle-outline' : 'mdi-plus-circle-outline'"
          :icon-color="staff.service_id ? 'negative' : null"
          @click="onAssignItem(staff)"
          @icon-click="!staff.service_id ? onAssignItem(staff) : onUnassignItem(staff)"
        />
      </q-list>
      <q-list
        :key="selectionKey"
        class="q-mt-sm"
      >
        <q-item-label
          header
        >
          {{ t('common.equipment') }}
        </q-item-label>
        <list-item
          v-for="equipment in equipmentTagServices"
          :key="equipment.id"
          :avatar="equipment.service_id ? (equipment.service_avatar_file || equipment.service_avatar_url) : null"
          :avatar-icon="equipment.service_id ? null : 'mdi-crop-free'"
          :label="equipment.service_id ? equipment.service_name : equipment.tag.name"
          :caption="equipment.service_id ? equipment.tag.name : null"
          clickable
          :icon="equipment.service_id ? 'mdi-minus-circle-outline' : 'mdi-plus-circle-outline'"
          :icon-color="equipment.service_id ? 'negative' : null"
          @click="onAssignItem(equipment)"
          @icon-click="!equipment.service_id ? onAssignItem(equipment) : onUnassignItem(equipment)"
        />
      </q-list>
    </q-card-section>
    <q-space />
  </q-card>
  <q-dialog
    :model-value="assignItem !== null"
    :maximized="isMobile"
    persistent
  >
    <project-assign
      v-if="assignItem !== null"
      :model-value="assignItem"
      :project="projectItem"
      @update="onAssignUpdate"
      @cancel="assignItem = null"
    />
  </q-dialog>
  <q-dialog
    :model-value="editItem !== null"
    :maximized="isMobile"
    persistent
  >
    <project-edit
      v-if="editItem !== null"
      :model-value="editItem"
      @delete="emit('update')"
      @update="onProjectUpdate"
      @cancel="editItem = null"
    />
  </q-dialog>
</template>
