<script setup>
import dayjs from 'dayjs'
import { ref, computed } from 'vue'

import { planningApi } from '@/apis/planning.api'
import { usePlanningStore } from '@/stores/planning.store'
import { useNotify } from '@/composables/notify'
import { useStepper } from '@/composables/stepper'
import { useIsUpdated } from '@/composables/object'

import NavigateBack from '@/components/shared/NavigateBack.vue'
import InputDateSelector from '@/components/shared/InputDateSelector.vue'
import TimeSelector from '@/components/shared/TimeSelector.vue'
import StepperNavigation from '@/components/shared/StepperNavigation.vue'
import ConfirmDialog from '@/components/shared/ConfirmDialog.vue'

import i18n from '@/plugins/i18n'

const planningStore = usePlanningStore()
const notify = useNotify()
const { step, nextStep, prevStep } = useStepper()
const t = i18n.global.t

await Promise.all([
  planningStore.fetchListingsAsync(true),
  planningStore.fetchListingItemsAsync(true),
])

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
})

const editItem = ref({
  start: dayjs().set('hour', 8).set('minute', 0).set('second', 0).set('millisecond', 0).toISOString(),
  end: dayjs().set('hour', 20).set('minute', 0).set('second', 0).set('millisecond', 0).toISOString(),
  color: '#027be3'
})

if (props.modelValue.id) {
  editItem.value = (await planningApi.getProjectByIdAsync(props.modelValue.id)).data
  editItem.value.listing_items = (await planningApi.getProjectListingItemsAsync(props.modelValue.id)).data || []
}

const { isUpdated } = useIsUpdated(editItem.value)
const emit = defineEmits(['update', 'cancel', 'delete'])

const availableListingItems = computed(() => {
  let items = []
  if (editItem.value.listing) {
    items = planningStore.listingItems.filter(item => item.listing === editItem.value.listing)
  }
  if (listingItemFilter.value) {
    items = items.filter(item => item.name.toLowerCase().indexOf(listingItemFilter.value.toLowerCase()) > -1)
  }
  return items
})

const selectedService = ref(null)

const onListingItemAdd = (id) => {
  const nextId = Math.min(Math.min(...editItem.value.listing_items.map(i => i.id).filter(i => i < 0)) - 1, -1)
  editItem.value.listing_items.push({id: nextId, listing_item: id})
  selectedService.value = null
}

const onListingItemDelete = (id) => {
  editItem.value.listing_items = editItem.value.listing_items.filter(i => i.id !== id.id)
}

const currentListingItems = computed(() => {
  return editItem.value.listing_items.map(i => ({...i, name: planningStore.listingItems.find(j => j.id === i.listing_item).name})).sort((a, b) => a.name.localeCompare(b.name))
})

const servicesSelect = ref(null)
const listingItemFilter = ref('')

const onListingItemFilter = (val, update) => {
  if (val === '') {
    update(() => {
      listingItemFilter.value = ''
    })
    return
  }

  update(() => {
    listingItemFilter.value = val.toLowerCase()
  })
}
const onListingChange = (val) => {
  editItem.value.listing = val
  editItem.value.listing_items = []
}

const onUpdate = async() => {
  try {
    await planningStore.upsertProjectAsync(editItem.value)
    notify.success(t('info.updatedSuccessfully'))
    emit('update', editItem.value)
  } catch (e) {
    notify.error(t('error.saveFailed'))
  }
}

const onCancel = () => emit('cancel')

const confirmDialogVisibility = ref(false)

const onConfirmDelete = async() => {
  confirmDialogVisibility.value = true
}

const onDelete = async() => {
  try {
    confirmDialogVisibility.value = false
    await planningStore.deleteProjectAsync(editItem.value.id)
    notify.success(t('info.deletedSuccessfully'))
    emit('delete')
  } catch (e) {
    notify.error(t('error.deleteFailed'))
  }
}

</script>
<template>
  <q-card>
    <confirm-dialog
      v-model="confirmDialogVisibility"
      icon="mdi-alert"
      icon-color="warning"
      :message="i18n.global.t('common.confirmDelete')"
      @confirm="onDelete"
    />
    <q-card-section>
      <navigate-back
        :text="editItem.id ? t('common.editAGreatProject') : t('common.createAGreatProject')"
        @back="onCancel"
      />
      <q-space />
    </q-card-section>
    <q-card-section class="q-px-sm q-pt-none">
      <q-stepper
        v-model="step"
        class="q-py-none"
      >
        <q-step
          :name="1"
          :title="t('common.basicInfo')"
          icon="mdi-folder-plus"
          :done="!!editItem.id || step > 1"
          :header-nav="!!editItem.id || step > 1"
        >
          {{ t('project.edit.addBasicInfoDesc') }}
          <q-input
            v-model="editItem.name"
            class="q-mt-sm"
            outlined
            :label="t('common.name')"
          />
          <q-select
            :model-value="editItem.listing"
            class="q-mt-sm"
            behavior="menu"
            :label="t('common.priceList')"
            option-value="id"
            option-label="name"
            :options="planningStore.listings"
            @update:model-value="onListingChange"
          />
          <q-input
            v-model="editItem.journalist"
            class="q-mt-sm"
            outlined
            :label="t('common.journalist')"
          />
          <q-input
            v-model="editItem.location"
            class="q-mt-sm"
            outlined
            :label="t('common.location')"
          />
          <q-input
            v-model="editItem.description"
            class="q-mt-sm"
            outlined
            :label="t('common.description')"
          />
          <div
            class="row align-middle q-mt-sm"
          >
            <q-input
              v-model="editItem.color"
              outlined
              :label="t('common.color')"
              style="flex-grow:1"
            >
              <template #append>
                <q-avatar
                  :style="{'background-color': editItem.color }"
                >
                  <q-popup-proxy
                    cover
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-color
                      v-model="editItem.color"
                      no-header
                      no-footer
                    />
                  </q-popup-proxy>
                </q-avatar>
              </template>
            </q-input>
          </div>
          <stepper-navigation
            :disable="!editItem.name || editItem.name === '' || !editItem.listing"
            :continue="nextStep"
          />
        </q-step>

        <q-step
          :name="2"
          :title="t('project.edit.addDateAndTime')"
          icon="mdi-calendar-clock"
          :done="!!editItem.id || step > 2"
          :header-nav="!!editItem.id || step > 2"
        >
          <input-date-selector
            v-model="editItem"
            class="q-mt-sm"
            :label="t('common.startDate')"
          />
          <input-date-selector
            v-model="editItem"
            end
            class="q-mt-sm"
            :label="t('common.endDate')"
          />
          <time-selector
            v-model="editItem.start"
            class="q-mt-sm"
            :label="t('common.startTime')"
          />
          <time-selector
            v-model="editItem.end"
            end
            class="q-mt-sm"
            :label="t('common.endTime')"
          />
          <stepper-navigation
            :continue="nextStep"
            :back="prevStep"
          />
        </q-step>

        <q-step
          :name="3"
          :title="t('project.edit.addServices')"
          icon="mdi-filmstrip"
          :done="!!editItem.id || step > 3"
          :header-nav="!!editItem.id || step > 3"
        >
          <q-list
            class="q-mt-sm"
          >
            <q-item
              v-for="listingItem in currentListingItems"
              :key="listingItem.id"
              v-ripple
              clickable
            >
              <q-item-section>
                <q-item-label>{{ listingItem.name }}</q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-icon
                  name="mdi-minus-circle-outline"
                  color="negative"
                  flat
                  @click="onListingItemDelete(listingItem)"
                />
              </q-item-section>
            </q-item>
          </q-list>

          <q-select
            ref="servicesSelect"
            v-model="selectedService"
            class="q-mt-sm"
            behavior="menu"
            :label="t('common.service', 2)"
            option-value="id"
            option-label="name"
            :options="availableListingItems"
            @filter="onListingItemFilter"
            @update:model-value="onListingItemAdd"
          />
          <stepper-navigation
            :disable="!editItem.listing_items || editItem.listing_items.length === 0"
            :continue="nextStep"
            :back="prevStep"
          />
        </q-step>

        <q-step
          :name="4"
          :title="t('project.edit.addAttachments')"
          caption="Optional"
          icon="mdi-attachment"
          :done="!!editItem.id"
          :header-nav="!!editItem.id"
        >
          {{ t('project.edit.addAttachmentsDesc') }}

          <q-file
            v-model="editItem.attachments"
            class="q-mt-sm"
            label="Pick files"
            outlined
            use-chips
            multiple
          >
            <template #prepend>
              <q-icon name="mdi-attachment" />
            </template>
          </q-file>

          <stepper-navigation
            :back="prevStep"
            :finish="editItem.id ? null : onUpdate"
            finish-label="Create project"
          />
        </q-step>
      </q-stepper>
    </q-card-section>
    <q-space />
    <q-card-section
      v-if="editItem?.id"
      class="align-center"
    >
      <q-btn
        class="large-btn q-mr-sm"
        color="negative"
        :label="t('common.deleteProject')"
        @click="onConfirmDelete()"
      />
      <q-btn
        v-if="isUpdated"
        class="large-btn"
        color="positive"
        :disable="editItem.status !== planningStore.serviceStatusEnum.PLANNED"
        :label="t('common.updateProject')"
        @click="onUpdate()"
      />
    </q-card-section>
  </q-card>
</template>
