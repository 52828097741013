<script setup>
import { computed, ref } from 'vue'

import { useDefaultKeyHandler as useDefaultGridKeyHandler } from '@/composables/data-grid'
import { initPortalStoreAsync, usePortalStore } from '@/stores/portal.store.js'
import { initWebSiteStoreAsync, useWebSiteStore } from '@/stores/web-site.store.js'
import { useOrientation, useDetectDevice } from '@/composables/device'

import { initPlanningStoreAsync } from '@/stores/planning.store'

import AvatarImage from '@/components/shared/AvatarImage.vue'
import BreadCrumb from '@/components/shared/BreadCrumb.vue'
import LanguageSelector from '@/components/shared/LanguageSelector.vue'
import SideBar from '@/components/shared/SideBar.vue'

useDefaultGridKeyHandler()

await Promise.all([
  initPortalStoreAsync(),
  initWebSiteStoreAsync(),
  initPlanningStoreAsync()
])

const { isLandscape } = useOrientation()
const { isMobile } = useDetectDevice()
const portalStore = usePortalStore()
const webSiteStore = useWebSiteStore()

const drawer = ref(!isMobile.value)

const userName = computed(() => {
  if (webSiteStore.user && webSiteStore.user.firstName) {
    return `${webSiteStore.user.firstName} ${webSiteStore.user.lastName}`
  }
  return webSiteStore.user?.username ?? ''
})

const onLogoutAsync = async() => await webSiteStore.logoutAsync()

</script>
<template>
  <q-layout
    view="hHh Lpr lFf"
  >
    <q-header
      elevated
      class="header"
    >
      <q-toolbar
        class="text-white toolbar-background"
        elevated
      >
        <q-btn
          flat
          dense
          round
          aria-label="Menu"
          icon="mdi-menu"
          @click="drawer = !drawer"
        />
        <div class="title">
          <span
            v-if="isLandscape"
            class="text-h6"
          >
            Media manager
          </span>
          <bread-crumb
            v-if="isMobile"
            class="q-ml-md"
          />
        </div>
        <q-space />
        <language-selector
          v-if="isLandscape"
          inverted
        />
        <q-separator
          v-if="isLandscape"
          dark
          vertical
          inset
          class="q-mx-md"
        />
        <q-btn
          v-if="isLandscape"
          round
          flat
          icon="mdi-dots-vertical"
        >
          <q-menu
            anchor="bottom right"
            self="top right"
          >
            <div class="row no-wrap q-pa-md">
              <div class="column">
                <div class="text-h6 q-mb-md">
                  {{ $t('common.setting', 2) }}
                </div>
              </div>

              <q-separator
                vertical
                inset
                class="q-mx-lg"
              />

              <div class="column items-center">
                <avatar-image size="4.5rem" />
                <div
                  class="text-subtitle1 q-mt-md q-mb-xs"
                >
                  {{ userName }}
                </div>

                <q-btn
                  v-close-popup
                  color="primary"
                  :label="$t('common.logout')"
                  size="sm"
                  @click="onLogoutAsync"
                />
              </div>
            </div>
            <q-list class="menu-list">
              <q-separator spaced />
              <q-item
                v-ripple
                tag="label"
              >
                <q-item-section>
                  <q-item-label>{{ $t('common.version') }}</q-item-label>
                </q-item-section>
                <q-item-section side>
                  <q-item-label>{{ portalStore.version }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </q-toolbar>
    </q-header>
    <SideBar v-model:drawer="drawer" />
    <q-page-container>
      <q-page padding>
        <bread-crumb v-if="!isMobile" />
        <router-view v-slot="{ Component }">
          <transition
            appear
            mode="out-in"
            duration="500"
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
          >
            <component
              :is="Component"
              :key="portalStore.reloadKey"
            />
          </transition>
        </router-view>
      </q-page>
      <q-page-sticky
        position="bottom-right"
        :offset="[18, 18]"
      >
        <q-fab
          v-if="isMobile"
          fab
          icon="mdi-chevron-up"
          color="primary"
          direction="up"
        >
          <q-fab-action
            v-for="fabAction in webSiteStore.fabActions"
            :key="fabAction.id"
            :color="fabAction.color || 'accent'"
            :icon="fabAction.icon"
            @click="fabAction.onClick"
          />
        </q-fab>
      </q-page-sticky>
    </q-page-container>
  </q-layout>
</template>

<style scoped lang="scss">
@import '@/styles/quasar.variables.scss';

.header {
  z-index: 1000;
}
.menu-list {
  min-width: 20rem;
}
.toolbar-background {
  background: linear-gradient(90deg, $primary, darken($primary, 10%));
}
.title {
  display: flex;
  align-items: baseline;
}
.breadcrumb {
  font-size: small;
  display: flex;
  align-items: baseline;
  z-index: 2;
}
</style>
