<script setup>
import i18n from '@/plugins/i18n'
import { ref, computed} from 'vue'
import { useNotify } from '@/composables/notify'
import ProjectListItem from '@/components/shared/ProjectListItem.vue'
import ListItem from '@/components/shared/ListItem.vue'
import EquipmentCheckOutIn from '@/components/shared/EquipmentCheckOutIn.vue'
import ConfirmDialog from '@/components/shared/ConfirmDialog.vue'

import router from '@/router'
import { usePlanningStore } from '@/stores/planning.store'
import { usePortalStore } from '@/stores/portal.store'
import { useWebSiteStore } from '@/stores/web-site.store'

const notify = useNotify()

const t = i18n.global.t
const planningStore = usePlanningStore()
const portalStore = usePortalStore()
const webSiteStore = useWebSiteStore()

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['update'])

const selectedProject = ref(props.modelValue)
const projectListingItemServices = ref([])
const checkOutInDialogItem = ref(null)
const checkInDialogItem = ref(null)

const isProduction = computed(() => selectedProject.value.id && selectedProject.value.status === planningStore.projectStatusEnum.PLANNED)
const allowedTagIds = computed(() => projectListingItemServices.value.filter(i => i.service_type === portalStore.serviceTypeEnum.EQUIPMENT && ((isProduction.value && i.service_status === portalStore.serviceStatusEnum.AVAILABLE) || (!isProduction.value && i.service_status === portalStore.serviceStatusEnum.COMMITTED))).map(i => i.tag_id))
const allowedUuids = computed(() => projectListingItemServices.value.filter(i => i.service_type === portalStore.serviceTypeEnum.EQUIPMENT && !isProduction.value && i.service_status === portalStore.serviceStatusEnum.COMMITTED).map(i => i.service_uuid))

const refreshProjectItemServicesAsync = async() => {
  projectListingItemServices.value = (await planningStore.fetchProjectListingItemServicesByProjectIdAsync(selectedProject.value.id)).data?.map(i => ({
    ...i,
    tag: portalStore.getLocalizedTags().find(t => t.id === i.tag_id),
  })).sort((a, b) => a.service_type - b.service_type || a.service_name?.localeCompare(b.service_name)) || []
}
await refreshProjectItemServicesAsync()

const onListItemClick = (item) => {
  if (!item.service_id) {
    webSiteStore.editedProjectId = selectedProject.value.id
    router.push({name: 'projects' })
  }
  if (item.service_id && item.service_type === portalStore.serviceTypeEnum.EQUIPMENT) {
    checkOutInDialogItem.value = item
  }
}

const onListItemIconClick = (item) => {
  if (item.service_id && item.service_type === portalStore.serviceTypeEnum.EQUIPMENT) {
    if (isProduction.value && item.service_status === portalStore.serviceStatusEnum.COMMITTED) {
      checkInDialogItem.value = item
    } else {
      checkOutInDialogItem.value = item
    }
  }
}

const getListItemName = (item) => {
  if (item.tag.type === portalStore.serviceTypeEnum.STAFF && item.service_id) {
    return `${item.service_name} ${item.service_first_name}`
  }
  return item.service_id ? item.service_name : item.tag.name
}

const getListItemIcon = (item) => {
  if (item.tag.type === portalStore.serviceTypeEnum.EQUIPMENT && item.service_id) {
    switch (item.service_status) {
      case portalStore.serviceStatusEnum.COMMITTED:
        return isProduction.value ? 'mdi-checkbox-marked-circle-outline' : 'mdi-qrcode-scan'
      case portalStore.serviceStatusEnum.AVAILABLE:
        return !isProduction.value ? 'mdi-checkbox-marked-circle-outline' : 'mdi-qrcode-scan'
      case portalStore.serviceStatusEnum.UNAVAILABLE:
        return 'mdi-alert'
    }
  }
  return ''
}

const getListItemIconColor = (item) => {
  if (item.tag.type === portalStore.serviceTypeEnum.EQUIPMENT && item.service_id) {
    if (item.service_status === portalStore.serviceStatusEnum.UNAVAILABLE || (isProduction.value && item.service_status !== portalStore.serviceStatusEnum.COMMITTED) || (!isProduction.value && item.service_status !== portalStore.serviceStatusEnum.AVAILABLE)) {
      return 'warning'
    }
    return (isProduction.value && item.service_status === portalStore.serviceStatusEnum.COMMITTED) || (!isProduction.value && item.service_status === portalStore.serviceStatusEnum.AVAILABLE) ? 'positive' : 'grey-5'
  }
  return ''
}

const getListItemChipsCaptions = (item) => {
  return item.service_status === portalStore.serviceStatusEnum.UNAVAILABLE ? [{label: t('common.unavailable'), color: 'warning', textColor: 'white'}] : []
}

const onEquipmentCheckOutUpdate = (update = true) => {
  if (update) {
    refreshProjectItemServicesAsync()
  }
  checkOutInDialogItem.value = null
}

const onEquipmentCheckInUpdate = async(update = true) => {
  if (update) {
    await planningStore.upsertEquipmentAsync({
      id: checkInDialogItem.value.service_id,
      status: portalStore.serviceStatusEnum.AVAILABLE,
    })
    await refreshProjectItemServicesAsync()
  }
  checkInDialogItem.value = null
}

const allowProjectAction = computed(() => isProduction.value ? projectListingItemServices.value.filter(i => i.service_type === portalStore.serviceTypeEnum.EQUIPMENT).every(i => i.service_status === portalStore.serviceStatusEnum.COMMITTED) : projectListingItemServices.value.filter(i => i.service_id && i.service_type === portalStore.serviceTypeEnum.EQUIPMENT).every(i => i.service_status === portalStore.serviceStatusEnum.AVAILABLE))
const allowScan = computed(() => projectListingItemServices.value.some(i => i.service_id && i.service_type === portalStore.serviceTypeEnum.EQUIPMENT && ((isProduction.value && i.service_status === portalStore.serviceStatusEnum.AVAILABLE) || (!isProduction.value && i.service_status === portalStore.serviceStatusEnum.COMMITTED))))
const firstAlert = ref(true)
const onProjectClick = async() => {
  if (allowScan.value) {
    notify.warning(isProduction.value ? t('warning.checkOutAllEquipmentBeforeProceeding') : t('warning.checkInAllEquipmentBeforeProceeding'))
    return
  }
  if (isProduction.value && firstAlert.value && projectListingItemServices.value.some(i => !i.service_id || ((isProduction.value && i.service_status !== portalStore.serviceStatusEnum.COMMITTED) || (!isProduction.value && i.service_status !== portalStore.serviceStatusEnum.AVAILABLE)))) {
    notify.warning(t('warning.continueOnServiceMissing'))
    firstAlert.value = false
    return
  }
  await planningStore.upsertProjectAsync({
    id: selectedProject.value.id,
    status: isProduction.value ? planningStore.projectStatusEnum.PRODUCTION : planningStore.projectStatusEnum.POST_PRODUCTION,
  })
  emit('update')
}

const onGenericScanClick = () => {
  checkOutInDialogItem.value = projectListingItemServices.value.some(i => i.service_type === portalStore.serviceTypeEnum.EQUIPMENT && ((isProduction.value && i.service_status === portalStore.serviceStatusEnum.AVAILABLE) || (!isProduction.value && i.service_status === portalStore.serviceStatusEnum.COMMITTED))) ? projectListingItemServices.value.find(i => i.service_type === portalStore.serviceTypeEnum.EQUIPMENT && ((isProduction.value && i.service_status === portalStore.serviceStatusEnum.AVAILABLE) || (!isProduction.value && i.service_status === portalStore.serviceStatusEnum.COMMITTED))) : null
}

</script>
<template>
  <q-card>
    <confirm-dialog
      :model-value="checkInDialogItem !== null"
      icon="mdi-alert"
      icon-color="warning"
      :message="`${t('common.confirmCheckIn')}`"
      @confirm="onEquipmentCheckInUpdate"
      @cancel="onEquipmentCheckInUpdate(false)"
    />
    <q-dialog
      :model-value="checkOutInDialogItem !== null"
      maximized
    >
      <equipment-check-out-in
        :equipment-id="checkOutInDialogItem?.service_id"
        :allowed-tag-ids="allowedTagIds"
        :allowed-uuids="allowedUuids"
        :is-production="isProduction"
        @update="onEquipmentCheckOutUpdate()"
        @cancel="onEquipmentCheckOutUpdate(false)"
      />
    </q-dialog>
    <q-card-section>
      <project-list-item
        hide-color
        :item="selectedProject"
      />
    </q-card-section>

    <q-separator v-if="selectedProject.id" />
    <q-card-section
      v-if="selectedProject.id"
      style="max-height: 50vh"
      class="scroll"
    >
      <list-item
        v-for="item in projectListingItemServices"
        :key="item.id"
        :item="item"
        clickable
        :avatar="item.service_id ? (item.service_avatar_file || item.service_avatar_url) : null"
        :avatar-icon="item.service_id ? null : 'mdi-crop-free'"
        :label="getListItemName(item)"
        :icon-color="getListItemIconColor(item)"
        :icon="getListItemIcon(item)"
        :caption-chips="getListItemChipsCaptions(item)"
        @click="onListItemClick(item)"
        @icon-click="onListItemIconClick(item)"
      />
    </q-card-section>
    <q-separator v-if="selectedProject.id" />
    <q-card-section
      v-if="selectedProject.id && selectedProject.status !== planningStore.projectStatusEnum.POST_PRODUCTION"
      class="align-middle align-center"
    >
      <q-btn
        :color="allowProjectAction ? 'positive' : 'warning'"
        icon="mdi-play"
        :label="isProduction ? t('common.startProduction') : t('common.startPostProduction')"
        @click="onProjectClick"
      />
      <q-btn
        class="q-ml-md"
        icon="mdi-qrcode-scan"
        color="primary"
        :disabled="!allowScan"
        @click="onGenericScanClick"
      />
    </q-card-section>
  </q-card>
</template>

