<script setup>
import { useI18n } from 'vue-i18n'
import { DxDataGrid } from 'devextreme-vue/data-grid'
import { useGridRef, useODataSource, useDefaultExport, useDefaultEditing, useDefaultGridOptions, useDefaultDialogTitle, useLookup } from '@/composables/data-grid'

import { usePlanningStore } from '@/stores/planning.store'
import { usePortalStore } from '@/stores/portal.store'

import { planningApi } from '@/apis/planning.api'

const gridId = 'project-details-grid'

const i18n = useI18n()
const { gridRef } = useGridRef()
const { defaultDialogTitle } = useDefaultDialogTitle()

const planningStore = usePlanningStore()
const portalStore = usePortalStore()

const props = defineProps({
  data: Object
})

const projectId = props.data?.key

const dataSource = useODataSource(planningApi.getAbsoluteUrl() + planningApi.projectListingItemServicesUrl)
dataSource.filter(['project_id', '=', projectId])

const { exportOptions, onExporting } = useDefaultExport({ fileName: i18n.t('common.listingItem', 2)})

const getFilteredListingItems = () => {
  return {
    store: planningStore.listingItems.filter(item => item.listing_id === props.data.listing),
    sort: 'name',
  }
}

const getFilteredServices = (e) => {
  let filter = null
  if (e?.data?.tag_id) {
    filter = (i) => i.tags.includes(e.data?.tag_id)
  }
  return {
    store: planningStore.getServices(),
    filter: filter,
    sort: 'name',
  }
}

const columns = [
  {
    dataField: 'listing_item_id',
    dataType: 'number',
    caption: i18n.t('common.listingItem'),
    lookup: useLookup({
      dataSource: getFilteredListingItems,
      displayExpr: (item) => {
        const listing = planningStore.listings.find(listing => listing.id === item.listing)
        return `${listing.name} - ${item.name}`
      }
    }),
    setCellValue: (newData, value, currentData) => {
      newData.listing_item_id = value
      newData.service_id = null
    },
  },
  {
    dataField: 'tag_id',
    dataType: 'number',
    caption: i18n.t('common.tag'),
    lookup: useLookup({
      dataSource: {
        store: portalStore.getLocalizedTags(),
        sort: 'name',
      },
      displayExpr: (item) => `${item.name}`,
    }),
    setCellValue: (newData, value, currentData) => {
      newData.tag_id = value
      newData.service_id = null
    },
  },
  {
    dataField: 'service_id',
    dataType: 'number',
    caption: i18n.t('common.service'),
    lookup: useLookup({
      dataSource: getFilteredServices,
      displayExpr: (item) => planningStore.getServiceName(item),
    }),
  },
  { dataField: 'start', caption: i18n.t('common.start'), dataType: 'datetime', validationRules: [{ type: 'required' }]},
  { dataField: 'end', caption: i18n.t('common.end'), dataType: 'datetime', validationRules: [{ type: 'required' }]},
]

const editing = useDefaultEditing(i => defaultDialogTitle(i, i18n.t('common.listingItem')), {
  colCount: 1,
  items: [
    {
      itemType: 'group',
      caption: i18n.t('common.mainInfo'),
      colCount: 3,
      items: [
        { dataField: 'listing_item_id' },
        { dataField: 'tag_id', editorOptions: { readOnly: true } },
        { dataField: 'service_id', editorOptions: { readOnly: true, showClearButton: true } },
        { dataField: 'start', editorType: 'dxDateBox', editorOptions: { type: 'datetime' } },
        { dataField: 'end', editorType: 'dxDateBox', editorOptions: { type: 'datetime' } },
      ]
    }
  ]
})

const onInitNewRow = (e) => {
  e.data.project_id = projectId
  e.promise = planningStore.fetchProjectByIdAsync(projectId).then((project) => {
    e.data.start = project.data.start
    e.data.end = project.data.end
  })
}

const onEditorPreparing = (e) => {
  if (e.parentType === 'dataRow' && e.dataField === 'listing_item_id') {
    if (e.row.data?.listing_item_id) {
      e.editorOptions.readOnly = true
    }
  }
  if (e.parentType === 'dataRow' && e.dataField === 'service_id') {
    e.editorOptions.readOnly = !e.row.data?.tag_id
  }
}

const gridOptions = useDefaultGridOptions({
  id: gridId,
  dataSource: dataSource,
  columns: columns,
  editing: editing,
  export: exportOptions,
  onInitNewRow: onInitNewRow,
  onEditorPreparing: onEditorPreparing,
  onExporting: onExporting,
  onRowUpdating: (e) => {
    e.newData.id = e.key
    e.newData.service = e.newData.service_id
  },
  onSaving: async(e) => {
    if (e.changes[0]?.type === 'insert') {
      e.cancel = true
      const payload = {
        project: e.changes[0].data.project_id,
        listing_item: e.changes[0].data.listing_item_id,
        start: e.changes[0].data.start,
        end: e.changes[0].data.end,
      }

      await planningStore.upsertProjectListingItemAsync(payload)
      gridRef.value.instance.cancelEditData()
      await gridRef.value.instance.refresh()
    }
  },
})

</script>
<template>
  <dx-data-grid
    ref="gridRef"
    v-bind="gridOptions"
  />
</template>
