import './lib/extensions'
import 'animate.css'
import 'devextreme/dist/css/dx.common.css'
import './config.js'

import { createApp } from 'vue'

import App from './App.vue'
import i18n from './plugins/i18n'
import pinia from './plugins/pinia'
import router from './router'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './plugins/quasar'
import httpApi from './plugins/httpapi'

createApp(App)
.use(Quasar, quasarUserOptions)
    .use(pinia)
    .use(i18n)
    .use(httpApi)
    .use(router)
    .mount('#app')

// Dev-extreme
import '@/plugins/devextreme'
