<script setup>
import { useI18n } from 'vue-i18n'
import { DxDataGrid } from 'devextreme-vue/data-grid'
import { DxTagBox } from 'devextreme-vue/tag-box'
import { useGridRef, useODataSource, useDefaultExport, useDefaultEditing, useDefaultGridOptions, useDefaultDialogTitle, useLookup } from '@/composables/data-grid'

import { usePortalStore } from '@/stores/portal.store'

import { planningApi } from '@/apis/planning.api'

const gridId = 'project-details-grid'

const i18n = useI18n()
const { gridRef } = useGridRef()
const { defaultDialogTitle } = useDefaultDialogTitle()

const portalStore = usePortalStore()

const props = defineProps({
  data: Object
})

const listingId = props.data?.key
const localizedTags = portalStore.getLocalizedTags()
const dataSource = useODataSource(planningApi.getAbsoluteUrl() + planningApi.listingItemsUrl)
dataSource.filter(['listing_id', '=', listingId])

const { exportOptions, onExporting } = useDefaultExport({ fileName: i18n.t('common.listingItem', 2)})

const columns = [
  {
    dataField: 'name',
    caption: i18n.t('common.name'),
    validationRules: [{ type: 'required' }],
    sortOrder: 'asc'
  },
  {
    dataField: 'description',
    caption: i18n.t('common.description'),
  },
  {
    dataField: 'tags',
    caption: i18n.t('common.tag', 2),
    lookup: useLookup({
      dataSource: localizedTags,
      valueExpr: 'id',
      displayExpr: 'name',
    }),
    setCellValue: (rowData, value) => (rowData.tags = value),
    cellTemplate: 'tag-cell-template',
    editCellTemplate: 'tag-edit-cell-template',
    validationRules: [{ type: 'required' }]
  },
  {
    dataField: 'price',
    caption: i18n.t('common.price'),
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
  },
]

const editing = useDefaultEditing(i => defaultDialogTitle(i, i18n.t('common.listingItem')), {
  colCount: 1,
  items: [
    {
      itemType: 'group',
      caption: i18n.t('common.mainInfo'),
      colCount: 5,
      items: [
        { dataField: 'name' },
        { dataField: 'description' },
        { dataField: 'tags', colSpan: 2 },
        { dataField: 'price', editorOptions: { format: { type: 'currency', precision: 2 } } }
      ]
    }
  ]
})

const onInitNewRow = (e) => {
  e.data.listing = listingId
}

const gridOptions = useDefaultGridOptions({
  id: gridId,
  dataSource: dataSource,
  columns: columns,
  editing: editing,
  export: exportOptions,
  onInitNewRow: onInitNewRow,
  onExporting: onExporting,
})

</script>
<template>
  <dx-data-grid
    ref="gridRef"
    v-bind="gridOptions"
  >
    <template #tag-cell-template="{ data: templateOptions }">
      <q-chip
        v-for="tag in templateOptions.data.tags"
        :key="tag"
        size="sm"
        :label="localizedTags.find(t => t.id === tag).name"
      />
    </template>
    <template #tag-edit-cell-template="{ data: templateOptions }">
      <dx-tag-box
        :value="templateOptions.data.tags"
        :data-source="localizedTags"
        value-expr="id"
        display-expr="name"
        @value-changed="(e) => templateOptions.setValue(e.value)"
      />
    </template>
  </dx-data-grid>
</template>
