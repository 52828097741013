<script setup>
import { useI18n } from 'vue-i18n'
import { DxDataGrid } from 'devextreme-vue/data-grid'
import { DxTagBox } from 'devextreme-vue/tag-box'
import { useGridRef, useODataSource, useLookup, useDefaultExport, useDefaultEditing, useDefaultGridOptions, useDefaultDialogTitle } from '@/composables/data-grid'

import { formatString, stringFormats } from '@/lib/utils'

import { planningApi } from '@/apis/planning.api'
import { usePortalStore } from '@/stores/portal.store'
import { usePlanningStore } from '@/stores/planning.store'

const gridId = 'staffs-grid'

const i18n = useI18n()
const portalStore = usePortalStore()
const planningStore = usePlanningStore()
const localizedTags = portalStore.getLocalizedTags().filter(i => i.type === planningStore.serviceTypeEnum.STAFF)
const localizedServiceStatuses = portalStore.getLocalizedServiceStatuses()
const localizedServiceAvailabilityTypes = portalStore.getLocalizedServiceAvailabilityTypes()
const localizedUserRoles = portalStore.getLocalizedUserRoles()
const localizedLanguages = portalStore.getLocalizedLanguages()
const editors = {}

const { gridRef } = useGridRef()
const { defaultDialogTitle } = useDefaultDialogTitle()

const gridDataSource = useODataSource(planningApi.getAbsoluteUrl() + planningApi.staffsUrl)
// const usersLookupDataSource = useODataStore(`${webSiteApi.getAbsoluteUrl()}${webSiteApi.usersUrl}?is_superuser=false&is_active=true`)

const { exportOptions, onExporting } = useDefaultExport({ fileName: i18n.t('common.staff', 2)})

const columns = [
  {
    dataField: 'name',
    caption: i18n.t('common.nameExt'),
    validationRules: [{ type: 'required' }],
    sortOrder: 'asc'
  },
  {
    dataField: 'first_name',
    caption: i18n.t('common.name'),
    validationRules: [{ type: 'required' }],
    sortOrder: 'asc'
  },
  {
    dataField: 'email',
    caption: i18n.t('common.email'),
    validationRules: [
      { type: 'email' },
      { type: 'custom', message: i18n.t('error.validation.emailRequiredForPortalAccess'), reevaluate: true, validationCallback: (e) => {
        return e.value || !e.data.role
      }}
    ]
  },
  {
    dataField: 'phone',
    caption: i18n.t('common.phone'),
    calculateDisplayValue: (rowData) => formatString(rowData.phone, stringFormats.PHONE),
    validationRules: [{ type: 'required' }],
  },
  {
    dataField: 'tags',
    caption: i18n.t('common.tag', 2),
    lookup: useLookup({
      dataSource: localizedTags,
      valueExpr: 'id',
      displayExpr: 'name',
    }),
    setCellValue: (rowData, value) => (rowData.tags = value),
    cellTemplate: 'tag-cell-template',
    editCellTemplate: 'tag-edit-cell-template',
    validationRules: [{ type: 'required' }]
  },
  {
    dataField: 'availability_type',
    caption: i18n.t('common.availabilityType'),
    lookup: useLookup({
      dataSource: localizedServiceAvailabilityTypes,
      valueExpr: 'id',
      displayExpr: 'name',
    }),
    cellTemplate: 'availability-type-cell-template',
    validationRules: [{ type: 'required' }],
  },
  {
    dataField: 'role',
    caption: i18n.t('common.role'),
    lookup: useLookup({
      dataSource: localizedUserRoles,
      valueExpr: 'id',
      displayExpr: 'name',
    }),
    cellTemplate: 'role-cell-template',
  },
  {
    dataField: 'preferred_language',
    caption: i18n.t('common.preferredLanguage'),
    lookup: useLookup({
      dataSource: localizedLanguages.map(l => ({ id: l.code, name: l.name })),
      valueExpr: 'id',
      displayExpr: 'name',
    }),
  },

  {
    dataField: 'status',
    caption: i18n.t('common.status'),
    lookup: useLookup({
      dataSource: localizedServiceStatuses,
      valueExpr: 'id',
      displayExpr: 'name',
    }),
    cellTemplate: 'status-cell-template',
    validationRules: [{ type: 'required' }]
  },
]

const editing = useDefaultEditing(i => defaultDialogTitle(i, i18n.t('common.project')), {
  colCount: 4,
  items: [
    {
      itemType: 'group',
      caption: i18n.t('common.mainInfo'),
      colCount: 4,
      colSpan: 3,
      items: [
        { dataField: 'first_name' },
        { dataField: 'name' },
        { dataField: 'email', colSpan: 2 },
        { dataField: 'phone', editorType: 'dxTextBox', editorOptions: { mask: stringFormats.PHONE } },
        { dataField: 'availability_type' },
        { dataField: 'tags', colSpan: 2 },
      ]
    },
    {
      itemType: 'group',
      caption: i18n.t('common.portalAccess'),
      colCount: 1,
      colSpan: 1,
      items: [
        { dataField: 'role', editorOptions: { showClearButton: true } },
        { dataField: 'preferred_language' },
      ]
    },
  ],
})

const getRoleColor = (role) => {
  switch (role) {
    case 0:
      return 'grey'
    case 1:
      return 'blue'
    case 2:
      return 'cyan'
    case 4:
      return 'pink'
    case 8:
      return 'green'
    case 16:
      return 'teal'
    default:
      return 'grey'
  }
}

const onEditorPreparing = (e) => {
  if (e.parentType === 'dataRow' && e.dataField === 'user_id') {
    e.editorOptions.onInitialized = (e) => { editors['user_id'] = e.component }
    const originalOnValueChanged = e.editorOptions.onValueChanged
    e.editorOptions.onValueChanged = (e) => {
      originalOnValueChanged(e)
      editors['role'].option('readOnly', !e.value)
      if (!e.value) {
        editors['role'].option('value', null)
      }
    }
  }
  if (e.parentType === 'dataRow' && e.dataField === 'role') {
    e.editorOptions.onInitialized = (e) => { editors['role'] = e.component }
  }
}

const onRowInserting = async(e) => (e.data.type = planningStore.serviceTypeEnum.STAFF)

const gridOptions = useDefaultGridOptions({
  id: gridId,
  columns: columns,
  dataSource: gridDataSource,
  editing: editing,
  export: exportOptions,
  onRowInserting: onRowInserting,
  onEditorPreparing: onEditorPreparing,
  onExporting: onExporting,
})

</script>
<template>
  <div>
    <dx-data-grid
      ref="gridRef"
      v-bind="gridOptions"
    >
      <template #tag-cell-template="{ data: templateOptions }">
        <q-chip
          v-for="tag in templateOptions.data.tags"
          :key="tag"
          size="sm"
          :label="localizedTags.find(t => t.id === tag).name"
        />
      </template>
      <template #tag-edit-cell-template="{ data: templateOptions }">
        <dx-tag-box
          :value="templateOptions.data.tags"
          :data-source="localizedTags"
          value-expr="id"
          display-expr="name"
          @value-changed="(e) => templateOptions.setValue(e.value)"
        />
      </template>
      <template #status-cell-template="{ data: templateOptions }">
        <q-chip
          size="sm"
          :label="localizedServiceStatuses.find(s => s.id === templateOptions.data.status).name"
          :color="templateOptions.data.status === 0 ? 'green' : templateOptions.data.status === 1 ? 'orange' : 'yellow'"
        />
      </template>
      <template #role-cell-template="{ data: templateOptions }">
        <q-chip
          v-if="templateOptions.data.role"
          size="sm"
          :label="localizedUserRoles.find(s => s.id === templateOptions.data.role)?.name"
          :color="getRoleColor(templateOptions.data.role)"
        />
      </template>
      <template #availability-type-cell-template="{ data: templateOptions }">
        <q-chip
          size="sm"
          :label="localizedServiceAvailabilityTypes.find(s => s.id === templateOptions.data.availability_type).name"
          :color="templateOptions.data.availability_type === 0 ? 'green' : templateOptions.data.availability_type === 1 ? 'green' : 'yellow'"
        />
      </template>
    </dx-data-grid>
  </div>
</template>
