import { createI18n } from 'vue-i18n'
// import { usePortalStore } from '@/stores/portal.store'
import { setAcceptLanguageHeader } from '@/apis/http.api'
import { nextTick } from 'vue'
import deMessages from '@/assets/lang/de.json'
import itMessages from '@/assets/lang/it.json'
import frMessages from '@/assets/lang/fr.json'
import esMessages from '@/assets/lang/es.json'
import nlMessages from '@/assets/lang/nl.json'

import { locale, loadMessages } from 'devextreme/localization'

loadMessages(deMessages)
loadMessages(itMessages)
loadMessages(frMessages)
loadMessages(esMessages)
loadMessages(nlMessages)

const i18n = createI18n({
  legacy: false, // you must set `false`, to use Composition API
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: {
    en: {
      'common.version': 'Version',
    }
  },
})

export const updateLocaleAsync = async(localeId, localizations) => {
  Object.keys(localizations).forEach(language => {
    const langDict = {}
    localizations[language].forEach(localization => {
      langDict[localization.key] = localization.value
    })
    i18n.global.setLocaleMessage(language, langDict)
  })

  locale(localeId)

  setAcceptLanguageHeader(localeId)

  i18n.global.locale.value = localeId

  return nextTick()
}

export const paramsSubstituteTranslate = (message, params = []) => {
  let messageTranslated = i18n.global.t(message)
  for (const param in params) {
    const re = new RegExp(`\\{${param}\\}`, 'g')
    messageTranslated = messageTranslated.replace(re, params[param])
  }
  const re = new RegExp(`\n`, 'g')
  messageTranslated = messageTranslated.replace(re, '<br/>')
  return messageTranslated
}

export const getTranslatedField = (item, field, languageCode = i18n.global.locale.value) => {
  if (!item.translations) {
    return item[field]
  }
  let currentTranslation = item.translations[languageCode]
  if (!currentTranslation) {
    currentTranslation = item.translations[i18n.global.fallbackLocale.value]
  }
  return currentTranslation[field] || item[field]
}

export const getTranslatedItems = (items, languageCode = i18n.global.locale.value) => {
  return items && items.map((item) => {
    return {
      ...item,
      name: getTranslatedField(item, 'name', languageCode)
    }
  })
}

export const getLocalizedItems = (items) => {
  return items.map((item) => {
    return {
      ...item,
      name: i18n.global.t(item.name)
    }
  })
}

export default i18n
