<script setup>
import i18n from '@/plugins/i18n'
import dayjs from 'dayjs'

import { ref, onMounted } from 'vue'
import { DxResource, DxScheduler } from 'devextreme-vue/scheduler'
import { useDetectDevice, useOrientation } from '@/composables/device'

import { useODataSource } from '@/composables/data-grid'
import { useWebSiteStore } from '@/stores/web-site.store'
import { usePlanningStore } from '@/stores/planning.store'
import { usePortalStore } from '@/stores/portal.store'
import { useScheduleStore } from '@/stores/schedule.store'

import { getColorFromInitials } from '@/lib/utils.js'

import VacationEditor from '@/components/shared/VacationEditor.vue'
import EventProjectStaffEquipmentToggle from '@/components/shared/EventProjectStaffEquipmentToggle.vue'
import EventWarningToggle from '@/components/shared/EventWarningToggle.vue'
import EventVacationToggle from '@/components/shared/EventVacationToggle.vue'
import ProjectCheckOutIn from '@/components/shared/ProjectCheckOutIn.vue'
import VacationDetail from '@/components/shared/VacationDetail.vue'

import { scheduleApi } from '@/apis/schedule.api'

const t = i18n.global.t
const { isLandscape } = useOrientation()
const { isMobile } = useDetectDevice()
const dataSource = ref([])
const webSiteStore = useWebSiteStore()
const planningStore = usePlanningStore()
const portalStore = usePortalStore()
const scheduleStore = useScheduleStore()

const currentDate = ref(new Date().toISOString())
const currentView = ref(webSiteStore.schedulerViews.PROJECTS)
const editVacation = ref(null)
const equipmentDataSource = ref([])
const vacationDialog = ref(false)
const mounted = ref(false)
const projectsDataSource = ref([])
const scheduler = ref(null)
const selectedAppointment = ref(null)
const selectedEquipmentIds = ref([])
const selectedProjectIds = ref([])
const selectedStaffIds = ref([])
const staffDataSource = ref([])
const vacations = ref([])
const warnings = ref([])

const updateDataSourceAsync = async() => {
  dataSource.value = useODataSource(scheduleApi.getAbsoluteUrl() + scheduleApi.eventsUrl, {
    customParamsFn: () => {
      const params = {
        types: [scheduleStore.eventTypeEnum.PROJECT],
      }

      if (webSiteStore.showEventWarnings) {
        params.show_warnings = true
      }
      if (webSiteStore.showEventVacations) {
        params.show_vacations = true
      }

      if (currentView.value === webSiteStore.schedulerViews.PROJECTS) {
        params.service_type = portalStore.serviceTypeEnum.PROJECT
        params.project_ids = selectedProjectIds.value
        params.group_by_project = true
      }
      if (currentView.value === webSiteStore.schedulerViews.STAFF) {
        params.service_type = portalStore.serviceTypeEnum.STAFF
        params.staff_ids = selectedStaffIds.value
      }
      if (currentView.value === webSiteStore.schedulerViews.EQUIPMENT) {
        params.service_type = portalStore.serviceTypeEnum.EQUIPMENT
        params.equipment_ids = selectedEquipmentIds.value
      }
      return params
    },
    postProcess: (data) => {
      warnings.value = []
      return data.map(i => {
        if (webSiteStore.showEventWarnings) {
          i.warnings?.forEach(w => {
            if (!warnings.value.map(i => i.id).includes(w.id)) {
              warnings.value.push(w)
            }
          })
        }
        return {
          ...i,
          project: i.project_id === 0 ? planningStore.getVacationProject(t) : planningStore.getProjectById(i.project_id),
          staff: planningStore.getStaffById(i.staff_id),
          equipment: planningStore.getEquipmentById(i.equipment_id),
        }
      })
    },
  })
}

onMounted(async() => {
  if (webSiteStore.eventSchedulerDate) {
    currentDate.value = webSiteStore.eventSchedulerDate
    webSiteStore.eventSchedulerDate = null
  }

  await Promise.all([
    planningStore.fetchProjectsAsync(true),
    planningStore.fetchStaffsAsync(true),
    planningStore.fetchEquipmentsAsync(true),
  ])
  const projects = planningStore.projects.map(i => {
    return {
      ...i,
      visible: true,
    }
  })
  projectsDataSource.value = [planningStore.getVacationProject(t), ...projects]
  selectedProjectIds.value = projectsDataSource.value.map(i => i.id)

  staffDataSource.value = planningStore.staff?.map(i => {
    return {
      ...i,
      color: getColorFromInitials(i.name),
      visible: true,
    }
  })
  selectedStaffIds.value = staffDataSource.value.map(i => i.id)

  equipmentDataSource.value = planningStore.equipments?.map(i => {
    return {
      ...i,
      color: getColorFromInitials(i.name),
      visible: true,
    }
  })
  selectedEquipmentIds.value = equipmentDataSource.value.map(i => i.id)

  await updateDataSourceAsync()
  mounted.value = true
})

webSiteStore.fabActions = [
  {
    id: 'add',
    icon: 'mdi-calendar-plus',
    onClick: () => {
      vacationDialog.value = true
    },
  },
]

const schedulerViews = ref([
  {name: t('common.day'), type: 'day', intervalCount: 1, startDate: new Date(), maxAppointmentsPerCell: 'unlimited'},
  {name: `3 ${t('common.day', 2)}`, type: 'day', intervalCount: 3, startDate: new Date(), maxAppointmentsPerCell: 'unlimited'},
  {name: t('common.workWeek'), type: 'workWeek', intervalCount: 1, startDate: new Date(), maxAppointmentsPerCell: 'unlimited'},
  {name: t('common.week'), type: 'week', intervalCount: 1, startDate: new Date(), maxAppointmentsPerCell: 'unlimited'},
  {name: t('common.month'), type: 'month', intervalCount: 1, startDate: new Date(), maxAppointmentsPerCell: 'unlimited'}]
)

const onAppointmentClick = (e) => { selectedAppointment.value = e.appointmentData }

const onVacationInserting = (e) => {
  e.cancel = true
  editVacation.value = {
    start: dayjs(e.appointmentData.start).toISOString(),
    end: dayjs(e.appointmentData.end).toISOString(),
    service: webSiteStore.user.service_id,
  }
  vacationDialog.value = true
}

const hideVacationDialog = () => {
  editVacation.value = null
  vacationDialog.value = false
}

const onVacationUpdate = async() => {
  await updateDataSourceAsync()
  hideVacationDialog()
}

const onChange = async() => {
  selectedAppointment.value = null
  await updateDataSourceAsync()
}

const onProjectCheckOutInChange = async() => {
  await planningStore.fetchProjectsAsync(true)
  await onChange()
}

const onServiceViewChange = async(value) => {
  currentView.value = value
  await updateDataSourceAsync(value)
}

const onServiceSelectionChange = async(value) => {
  if (currentView.value === webSiteStore.schedulerViews.PROJECTS) {
    selectedProjectIds.value = value
  } else if (currentView.value === webSiteStore.schedulerViews.STAFF) {
    selectedStaffIds.value = value
  } else if (currentView.value === webSiteStore.schedulerViews.EQUIPMENT) {
    selectedEquipmentIds.value = value
  }
  await updateDataSourceAsync()
}

</script>
<template>
  <div>
    <q-dialog
      v-model="vacationDialog"
      full-width
    >
      <vacation-editor
        :model-value="editVacation"
        @update="onVacationUpdate"
        @cancel="hideVacationDialog"
      />
    </q-dialog>
    <q-dialog
      :model-value="selectedAppointment !== null"
      full-width
      @hide="selectedAppointment = null"
    >
      <project-check-out-in
        v-if="selectedAppointment.project_id !== 0"
        :model-value="selectedAppointment.project"
        @update="onProjectCheckOutInChange"
      />
      <vacation-detail
        v-else
        :model-value="selectedAppointment"
        @update="onChange"
      />
    </q-dialog>
    <div style="display: flex;">
      <event-project-staff-equipment-toggle
        v-if="mounted"
        :view="currentView"
        :projects-data-source="projectsDataSource"
        :staff-data-source="staffDataSource"
        :equipment-data-source="equipmentDataSource"
        @view-change="onServiceViewChange"
        @selection-change="onServiceSelectionChange"
      />
      <q-space />
      <event-vacation-toggle
        v-model="webSiteStore.showEventVacations"
        :vacations="vacations"
        @update:model-value="updateDataSourceAsync"
      />
      <event-warning-toggle
        v-model="webSiteStore.showEventWarnings"
        :warnings="warnings"
        @update:model-value="updateDataSourceAsync"
      />
    </div>
    <DxScheduler
      ref="scheduler"
      adaptivity-enabled
      show-current-time-indicator
      shade-until-current-time
      all-day-panel-mode="hidden"
      text-expr="project.name"
      start-date-expr="start"
      end-date-expr="end"
      key-expr="id"
      :start-day-hour="!isMobile ? 0 : isLandscape ? 6 : 0"
      :remote-filtering="true"
      :data-source="dataSource"
      :current-date="currentDate"
      :views="schedulerViews"
      :cell-duration="!isMobile ? 60 : isLandscape ? 120 : 60"
      :current-view="isLandscape ? t('common.week') : `3 ${t('common.day', 2)}`"
      :height="!isMobile ? '100%' : isLandscape ? 'calc(100vh - 5rem)' : 'calc(100vh - 5rem)'"
      :first-day-of-week="1"
      :editing="{ allowAdding: true, allowDeleting: true, allowUpdating: true, allowDragging: true, allowResizing: true }"
      appointment-template="AppointmentTemplateSlot"
      @appointment-click="(e)=>{e.cancel = true;}"
      @appointment-form-opening="onVacationInserting"
    >
      <template #AppointmentTemplateSlot="{ data }">
        <div
          style="display: flex; flex-direction: column; align-items: flex-start;"
          :class="{
            'event': true,
            'event-warning': data.appointmentData.warnings?.length > 0,
            'event-vacation': data.appointmentData.project_id === 0,
            'event-project-production': data.appointmentData.project?.status === planningStore.projectStatusEnum.PRODUCTION,
          }"
          @click="(e)=>{ e.stopPropagation(); onAppointmentClick(data) }"
        >
          <q-item-label class="q-mb-sm text-bold q-ml-sm q-mt-sm">
            {{ data.appointmentData.project?.name }}
            <q-icon
              v-if="data.appointmentData.project_id === 0 || data.appointmentData.warnings?.length"
              :name="data.appointmentData.project_id === 0 ? 'mdi-beach' : data.appointmentData.warnings?.length ? 'mdi-alert' : ''"
              size="xs"
              class="q-mb-sm"
            />
          </q-item-label>
          <q-chip
            v-if="data.appointmentData.project?.status === planningStore.projectStatusEnum.PRODUCTION"
            color="green"
            text-color="white"
            size="sm"
          >
            {{ t('common.projectStatus.production') }}
          </q-chip>
          <q-item-label
            v-if="currentView !== webSiteStore.schedulerViews.PROJECTS || data.appointmentData.project_id === 0"
            class="q-ml-sm"
            caption
          >
            {{ planningStore.getServiceName(data.appointmentData?.staff, true) }}
          </q-item-label>
          <q-item-label
            v-if="currentView !== webSiteStore.schedulerViews.PROJECTS"
            class="q-ml-sm"
            caption
          >
            {{ data.appointmentData.equipment?.name }}
          </q-item-label>
        </div>
      </template>
      <DxResource
        :data-source="projectsDataSource"
        :allow-multiple="true"
        :use-color-as-default="currentView === webSiteStore.schedulerViews.PROJECTS"
        field-expr="project_id"
        label="Project"
        display-expr="name"
      />
      <DxResource
        :data-source="staffDataSource"
        :allow-multiple="true"
        :use-color-as-default="currentView === webSiteStore.schedulerViews.STAFF"
        field-expr="staff_id"
        label="Staff"
        display-expr="name"
      />
      <DxResource
        :data-source="equipmentDataSource"
        :use-color-as-default="currentView === webSiteStore.schedulerViews.EQUIPMENT"
        :allow-multiple="true"
        field-expr="equipment_id"
        label="Equipment"
        display-expr="name"
      />
    </DxScheduler>
  </div>
</template>
<style lang="scss">
@import '@/styles/quasar.variables.scss';

.event {
  color: $white;
  & .text-caption {
    color: $white;
  }
}

.event-project-production {
  border-left: 4px solid $positive;
}

.event-warning {
  background-color: $warning;
  margin-left: 3px;
}

.event-vacation {

  & .text-caption {
    color: $primary;
  }
  background-image:
  repeating-linear-gradient(
      135deg,
      rgb(255, 255, 255),
      rgb(255, 255, 255) 4px,
      lighten($primary, 50) 4px,
      lighten($primary, 50) 9px
    );
  border: 2px solid lighten($primary, 20);
  border-left: 4px solid lighten($primary, 20);
  background-color: lighten($primary, 30);
  color: $primary;
}
</style>
