<script setup>
import { useI18n } from 'vue-i18n'
import { DxDataGrid } from 'devextreme-vue/data-grid'
import { useGridRef, useODataSource, useDefaultExport, useDefaultEditing, useDefaultGridOptions, useDefaultDialogTitle } from '@/composables/data-grid'

import { planningApi } from '@/apis/planning.api'

const gridId = 'listings-grid'

const i18n = useI18n()

const { gridRef } = useGridRef()
const { defaultDialogTitle } = useDefaultDialogTitle()

const gridDataSource = useODataSource(planningApi.getAbsoluteUrl() + planningApi.companiesUrl)

const { exportOptions, onExporting } = useDefaultExport({ fileName: i18n.t('common.company', 2)})

const columns = [
  {
    dataField: 'name',
    caption: i18n.t('common.name'),
    validationRules: [{ type: 'required' }],
    sortOrder: 'asc'
  },
  {
    dataField: 'description',
    caption: i18n.t('common.description'),
  },
  {
    dataField: 'contact',
    caption: i18n.t('common.contact'),
  },
  {
    dataField: 'is_client',
    caption: i18n.t('common.client'),
    dataType: 'boolean',
  },
  {
    dataField: 'is_rental',
    caption: i18n.t('common.rental'),
    dataType: 'boolean',
  },
]

const editing = useDefaultEditing(i => defaultDialogTitle(i, i18n.t('common.company')), {
  colCount: 1,
  items: [
    {
      itemType: 'group',
      caption: i18n.t('common.mainInfo'),
      colCount: 7,
      items: [
        { dataField: 'name' },
        { dataField: 'description', colSpan: 2 },
        { dataField: 'contact', colSpan: 2 },
        { dataField: 'is_client' },
        { dataField: 'is_rental' },
      ]
    },
  ],
})

const gridOptions = useDefaultGridOptions({
  id: gridId,
  columns: columns,
  dataSource: gridDataSource,
  editing: editing,
  export: exportOptions,
  onExporting: onExporting,
})

</script>
<template>
  <dx-data-grid
    ref="gridRef"
    v-bind="gridOptions"
  />
</template>
